import axios from "axios";
import axiosRetry from "axios-retry";
import Config from "../config.json";
import { apiURL } from "../urls";
import { UploadStatus } from "../Types/Types";

export const client = axios.create({
    baseURL: apiURL,
});

export const retryClient = axios.create({
    baseURL: apiURL,
});

export const privateClient = axios.create({
    baseURL: apiURL,
    withCredentials: true,
});

axiosRetry(retryClient, {
    retries: Config.RETRY_COUNT,
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 2000;
    },
});

export const getUploadStatus = (uploadId: string) => {
    return client.request<UploadStatus>({
        method: "GET",
        url: `/api/upload/${uploadId}/status`,
    });
};

export const checkAuth = () => {
    return privateClient.get("api/auth/check");
};

export const promiseTimeout = (
    ms: number,
    msg: string,
    promise: Promise<any>
) => {
    return Promise.race([
        new Promise((_, reject) => {
            setTimeout(() => reject(new Error(msg)), ms);
        }),
        promise,
    ]);
};

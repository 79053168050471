import React, { useRef, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useAPIError } from "../hooks/useAPIError";
import { client } from "../api/api";

import "./Delete.css";

const DeleteMyFilesComponent: React.FC = () => {
    const linkRef = useRef<HTMLTextAreaElement | null>(null);
    const [uploadID, setUploadID] = useState<string | null>(null);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [showDone, setShowDone] = useState(false);
    const { addError, removeError } = useAPIError();
    const linkRe = new RegExp("/d/([0-9a-zA-Z_-]{4,})$");

    const extractValidLink = (link: string | null): string | null => {
        if (!link) {
            return null;
        }
        let match = linkRe.exec(link!);
        if (!match || match.length < 2) {
            return null;
        }

        return match[1];
    };

    const handleFormChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        let uploadID = extractValidLink(event.target.value);
        setSubmitDisabled(!uploadID);
        setUploadID(uploadID);
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setSubmitDisabled(true);
        setShowDone(false);
        removeError();

        client
            .request({
                method: "DELETE",
                url: `/api/upload/${uploadID}`,
            })
            .then((resp) => {
                setUploadID(null);
                if (linkRef?.current) {
                    linkRef.current.value = "";
                }
                setShowDone(true);
            })
            .catch((err) => {
                addError(err);
                setSubmitDisabled(false);
            });
    };

    const handleClose = () => setShowDone(false);

    return (
        <>
            <div className="main-container">
                <h4 className="d-flex justify-content-center m-4">
                    Delete my files
                </h4>

                <Form onSubmit={onSubmit}>
                    <div className="d-flex justify-content-center align-content-center align-items-center my-5">
                        <Form.Group className="mb-3">
                            <Form.Label>Enter your link:</Form.Label>
                            <Form.Control
                                type="textarea"
                                as="textarea"
                                spellCheck="false"
                                rows={3}
                                name="link"
                                autoComplete="off"
                                required
                                onChange={handleFormChange}
                                ref={linkRef}
                            />
                        </Form.Group>
                    </div>

                    <Form.Group className="d-flex justify-content-center">
                        <Button
                            className="btn btn-primary rounded-pill"
                            type="submit"
                            disabled={submitDisabled}
                        >
                            Delete
                        </Button>
                    </Form.Group>
                </Form>
            </div>

            <Alert
                variant="info"
                onClose={handleClose}
                dismissible
                show={showDone}
                className="d-flex align-items-center my-0 fixed-bottom"
            >
                <h4>Files deleted!</h4>
            </Alert>
        </>
    );
};

export default DeleteMyFilesComponent;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Button,
    Col,
    ListGroup,
    Form,
    ProgressBar,
    Row,
    Spinner,
} from "react-bootstrap";
import { LinkModal } from "../LinkModal/LinkModal";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Turnstile from "../Turnstile/Turnstile";

import { UploadSession } from "./UploadSession";
import { useAPIError } from "../hooks/useAPIError";
import { convertUnits } from "../utils/Units";
import { getUploadStatus } from "../api/api";
import { apiURL } from "../urls";

import Config from "../config.json";

import "./Upload.css";
import "../FileList/FileList.css";

enum AppStep {
    Idle = 1,
    HaveFiles = 2,
    WaitingForChallenge = 3,
    InProgress = 4,
    Error = 5,
}

const UploadMediaComponent: React.FC = () => {
    const [validLink, setValidLink] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [appStep, setAppStep] = useState(AppStep.Idle);
    const [progress, setProgress] = useState<Map<string, number>>(
        new Map<string, number>()
    );
    const [key, setKey] = useState(new Date().getTime());
    const filesRef = useRef<HTMLInputElement | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [uploadURL, setUploadURL] = useState<string>("");
    const { addError, removeError } = useAPIError();
    const params = useParams();
    const navigate = useNavigate();
    const [uploadSession, setUploadSession] = useState<UploadSession | null>(
        null
    );

    const spinner = <Spinner animation="border" as="span" size="sm" />;
    const uploadSubmitInProgress = (
        <>
            {spinner}
            &nbsp;Uploading
        </>
    );
    const uploadSubmit = <>Send</>;
    const challengeInProgress = (
        <>
            {spinner}
            &nbsp;Verifying
        </>
    );

    const submitButtonContentMap = {
        [AppStep.Idle]: uploadSubmit,
        [AppStep.HaveFiles]: uploadSubmit,
        [AppStep.InProgress]: uploadSubmitInProgress,
        [AppStep.WaitingForChallenge]: challengeInProgress,
        [AppStep.Error]: <>Error</>
    }

    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            if (appStep === AppStep.InProgress) {
                event.preventDefault();
                event.returnValue =
                    "Please don't close the application until the upload is finished!";

                if (uploadSession?.uploadId) {
                    navigator.sendBeacon(
                        `${apiURL}/api/upload/${uploadSession?.uploadId}/leave`
                    );
                }
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [appStep, uploadSession]);

    useEffect(() => {
        setFiles([]);
    }, [key]);

    useEffect(() => {
        if (params.uploadId) {
            getUploadStatus(params.uploadId!)
                .then((resp) => {
                    if (resp.data.status === "created") {
                        setValidLink(true);
                    } else {
                        addError(Error("Invalid Link"));
                    }
                })
                .catch((err) => {
                    addError(err);
                });
        } else {
            setValidLink(true);
        }
    }, [params, addError]);

    const onBrowseFilesClick = () => {
        if (appStep > AppStep.HaveFiles) {
            return;
        }

        filesRef.current!.click();
    };

    const onDragOver = (e: React.DragEvent) => {
        e.dataTransfer.dropEffect = "copy";

        e.preventDefault();
    };

    const addFiles = (fileList: Array<File> | null) => {
        if (!fileList || !fileList.length) return;

        removeError();
        setFiles(fileList);
        setAppStep(AppStep.HaveFiles);
    };

    const onDrop = async (e: React.DragEvent) => {
        e.preventDefault();
        let droppedFiles = Array.from(e.dataTransfer.files);
        for (const f of droppedFiles) {
            const isDir = await isDirectory(f);
            if (isDir) {
                e.dataTransfer.effectAllowed = "none";
                e.dataTransfer.dropEffect = "none";
                return false;
            }
        }

        addFiles(droppedFiles);
    };

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        addFiles(e.target.files ? Array.from(e.target.files) : null);
    };

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        removeError();

        window.turnstile.execute();
        setAppStep(AppStep.WaitingForChallenge);
    };

    const onVerify = (token: string) => {
        const haveUploadId = !!params.uploadId;
        let uploadSession = new UploadSession(
            Array.from(files),
            setProgress,
            params.uploadId ?? ""
        );

        const handleUnload = (event: any) => {
            if (uploadSession?.uploadId) {
                navigator.sendBeacon(
                    `${apiURL}/api/upload/${uploadSession.uploadId}/abandon`
                );
            }
        };
        window.addEventListener("unload", handleUnload);

        setUploadSession(uploadSession);

        setAppStep(AppStep.InProgress);
        uploadSession
            ?.upload(token!)
            .then((resp) => {
                setUploadURL(uploadSession.downloadURL());
                setAppStep(AppStep.Idle);
                setShowModal(true);
            })
            .catch((err) => {
                if (haveUploadId) {
                    setAppStep(AppStep.Error);
                } else {
                    setAppStep(AppStep.HaveFiles);
                }
                addError(err);
                window.turnstile.reset();
            })
            .finally(() => {
                window.removeEventListener("unload", handleUnload);
            });
    };

    const onRecaptchaError = useCallback(() => {
        addError(new Error("Error loading challenge"));
    }, [addError]);

    const closeUploadDone = (e: React.MouseEvent<HTMLButtonElement> | null) => {
        setShowModal(false);
        setFiles([]);
        setAppStep(AppStep.Idle);
        setProgress(new Map<string, number>());
        setKey(new Date().getTime());
        window.turnstile.reset();

        if (params.uploadId) {
            navigate("/", { replace: true });
        }
    };

    const uploaderHeaderMessage =
        "Thank you for uploading your files to Bitdefender Support !";

    const uploadCompletedMessage = (
        <>
            <div>
                {params.uploadId && (
                    <span>
                        Your files will reach Bitdefender Support and your case
                        will be automatically updated with the upload link.{" "}
                    </span>
                )}
                <span>
                    Please keep the following link for reference or in the case
                    you want to request the deletion of your files:
                </span>
                <p></p>
            </div>
        </>
    );

    const renderContainer = () => {
        if (!validLink) {
            return <></>;
        }
        return (
            <div className="main-container">
                <Form
                    onSubmit={onFormSubmit}
                    key={key}
                    className="upload-form d-flex flex-column"
                >
                    <div
                        className="file-list-scrollable files-input"
                        onClick={onBrowseFilesClick}
                    >
                        <Form.Group controlId="files" className="upload-box">
                            <Form.Control
                                ref={filesRef}
                                type="file"
                                multiple
                                onChange={onFileChange}
                                hidden
                            />
                            <div className="d-flex mx-3">
                                <Col className="col-auto">
                                    <i className="bi bi-plus-circle-fill blue-icon"></i>
                                </Col>
                                <Col className="ms-3">
                                    Select or Drag your files for upload
                                </Col>
                            </div>
                        </Form.Group>

                        {files.length === 0 && (
                            <div className="mx-4 mt-5 d-grid instructions">
                                <Row className="my-2">
                                    Step 1: Select or Drag your files for upload
                                </Row>
                                <Row className="my-2">
                                    Step 2: Send your files
                                </Row>
                            </div>
                        )}

                        <div className="d-flex">
                            <ListGroup className="file-list">
                                {files.map((f, i) => (
                                    <ListGroup.Item
                                        className="mt-3 border"
                                        key={i}
                                    >
                                        <div className="d-flex justify-content-between">
                                            <Col md="auto">
                                                <i className="bi bi-file-earmark-text-fill icon-size"></i>
                                            </Col>
                                            <Col md="auto" className="ms-2">
                                                <span className="file-name">
                                                    {f.name}
                                                </span>
                                                <span className="file-size">
                                                    {convertUnits(f.size)}
                                                </span>
                                            </Col>
                                            <Col className="text-end">
                                                {progress.get(f.name) ===
                                                    100 && (
                                                    <i className="bi bi-check text-success fs-2"></i>
                                                )}
                                            </Col>
                                        </div>
                                        {progress.get(f.name) &&
                                            progress.get(f.name) !== 100 && (
                                                <ProgressBar
                                                    now={
                                                        progress.get(f.name) ||
                                                        0
                                                    }
                                                />
                                            )}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </div>
                    <Form.Group
                        className="d-flex justify-content-center"
                        controlId="submit"
                    >
                        <Button
                            type="submit"
                            disabled={appStep !== AppStep.HaveFiles}
                            className="btn btn-primary rounded-pill mt-2 mb-2 px-4"
                        >
                            {submitButtonContentMap[appStep]}
                        </Button>
                    </Form.Group>
                </Form>

                <div className="d-flex justify-content-end">
                    <Link to="/delete" className="delete">
                        Delete my files
                    </Link>
                </div>

                <LinkModal
                    uploadURL={uploadURL}
                    headerMessage={uploaderHeaderMessage}
                    message={uploadCompletedMessage}
                    show={showModal}
                    close={closeUploadDone}
                />

                <Turnstile
                    sitekey={Config.RECAPTCHA_SITE_KEY}
                    onVerify={onVerify}
                    onError={onRecaptchaError}
                    onTimeout={() => {
                        window.turnstile.reset();
                    }}
                    onRenderError={onRecaptchaError}
                    theme="dark"
                    size="normal"
                    appearance="interaction-only"
                    className="mt-4"
                    execution="execute"
                />
            </div>
        );
    };

    return (
        <div className="drop-target" onDragOver={onDragOver} onDrop={onDrop}>
            {renderContainer()}
        </div>
    );
};

const isDirectory = async (f: File) => {
    try {
        let ab = await f.slice(0, 1).arrayBuffer();
        if (ab.byteLength !== 1) {
            return true;
        }
    } catch (err) {
        return true;
    }

    return false;
};

export default UploadMediaComponent;

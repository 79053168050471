import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";

import "./LinkModal.css";

interface LinkModalProps {
    uploadURL: string;
    close: (e: React.MouseEvent<HTMLButtonElement> | null) => void;
    message: JSX.Element;
    headerMessage: string;
    show: boolean;
}

export const LinkModal: React.FC<LinkModalProps> = (props: LinkModalProps) => {
    const renderTooltip = (props: React.ComponentPropsWithRef<any>) => (
        <Tooltip id="button-tooltip" {...props}>
            Copy to clipboard
        </Tooltip>
    );

    const copyURLToClipboard = (e: React.MouseEvent<HTMLButtonElement>) => {
        navigator.clipboard.writeText(props.uploadURL);
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={() => props.close(null)}
                backdrop="static"
                keyboard={false}
                size="lg"
                dialogClassName="border-radius"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.headerMessage}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="my-2 mx-2">
                        <div>{props.message}</div>
                        <div className="bg-light border mt-4 p-2 d-flex justify-content-between">
                            {props.uploadURL}
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                            >
                                <i
                                    role="button"
                                    onClick={copyURLToClipboard}
                                    className="bi bi-clipboard-check d-flex justify-content-end"
                                />
                            </OverlayTrigger>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

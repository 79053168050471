import React, { useCallback, useState } from "react";
import { AxiosError } from "axios";

type ContextError = Error | AxiosError | null;

export interface IAPIErrorContext {
    error: ContextError;
    addError: (error: ContextError) => void;
    removeError: () => void;
}

export const APIErrorContext = React.createContext<IAPIErrorContext>({
    error: null,
    addError: (error: ContextError) => {},
    removeError: () => {},
});

export const APIErrorProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [error, setError] = useState<any | null>(null);

    const contextValue: IAPIErrorContext = {
        error: error,
        addError: useCallback(
            (error: ContextError) => error && setError(error),
            []
        ),
        removeError: useCallback(() => setError(null), []),
    };

    return (
        <APIErrorContext.Provider value={contextValue}>
            {children}
        </APIErrorContext.Provider>
    );
};

import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import UploadPage from "./Upload/UploadPage";
import DownloadComponent from "./Download/DownloadComponent";
import LoginComponent from "./Login/LoginComponent";
import UnauthorizedPage from "./Login/UnauthorizedPage";
import GenerateComponent from "./Generate/GenerateComponent";
import DeleteMyFilesComponent from "./Delete/Delete";
import RequireAuth from "./RequireAuth";

import { APIErrorProvider } from "./context/APIErrorProvider";
import { APIErrorNotification } from "./APIErrorNotification";
import { AuthProvider } from "./context/AuthContext";
import Logo from "./Logo";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css";

ReactDOM.render(
    <StrictMode>
        <Logo />
        <BrowserRouter>
            <APIErrorProvider>
                <AuthProvider>
                    <APIErrorNotification />

                    <Routes>
                        <Route path="/" element={<UploadPage />} />
                        <Route path="/:uploadId" element={<UploadPage />} />
                        <Route path="/" element={<RequireAuth />}>
                            <Route
                                path="/download"
                                element={<DownloadComponent />}
                            />
                            <Route
                                path="/d/:downloadId"
                                element={<DownloadComponent />}
                            />
                            <Route
                                path="/generate"
                                element={<GenerateComponent />}
                            />
                        </Route>
                        <Route path="/login" element={<LoginComponent />} />
                        <Route
                            path="/delete"
                            element={<DeleteMyFilesComponent />}
                        />
                        <Route
                            path="/unauthorized"
                            element={<UnauthorizedPage />}
                        />
                    </Routes>
                </AuthProvider>
            </APIErrorProvider>
        </BrowserRouter>
    </StrictMode>,
    document.getElementById("root")
);

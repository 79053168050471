import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { LinkModal } from "../LinkModal/LinkModal";
import { privateClient } from "../api/api";
import { UploadResponse } from "../Types/Types";
import { appURL } from "../urls";
import { useAPIError } from "../hooks/useAPIError";

enum TicketTypeEnum {
    Deskpro = "deskpro",
    Salesforce = "salesforce",
}

interface TicketType {
    name: string;
    value: TicketTypeEnum;
}

interface GenerateRequest {
    ticket_type: TicketTypeEnum;
    ticket_id: string;
}

const ticketTypes: TicketType[] = [
    { name: "Salesforce", value: TicketTypeEnum.Salesforce },
    { name: "Deskpro", value: TicketTypeEnum.Deskpro },
];

const GenerateComponent: React.FC = () => {
    const search = useLocation().search;
    const defaultTicket = ticketTypes[0];
    const intialData: GenerateRequest = {
        ticket_type: defaultTicket.value,
        ticket_id: "",
    };
    const [formData, setFormData] = useState(intialData);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [uploadURL, setUploadURL] = useState("");
    const { addError, removeError } = useAPIError();

    useEffect(() => {
        const params = new URLSearchParams(search);
        const ticketTypeParam = params.get("ticket_type");
        const ticketID = params.get("ticket_id");
        if (!ticketTypeParam || !ticketID) {
            return;
        }
        const ticketType = ticketTypes.find(
            (el) => el.name.toLowerCase() === ticketTypeParam.toLowerCase()
        );
        if (!ticketType) {
            return;
        }

        setFormData({
            ticket_id: ticketID,
            ticket_type: ticketType.value,
        });
    }, [search]);

    const handleChange = (e: React.BaseSyntheticEvent) => {
        setFormData((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        removeError();
        setSubmitDisabled(true);

        try {
            let resp = await privateClient.request<UploadResponse>({
                method: "POST",
                url: "/api/upload/generate",
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            setUploadURL(`${appURL}/${resp.data.id}`);
            setShowModal(true);
        } catch (err: any) {
            addError(err);
        } finally {
            setSubmitDisabled(false);
        }
    };

    const closeUploadDone = () => {
        setShowModal(false);
    };

    return (
        <div className="main-container">
            <h3 className="d-flex justify-content-center m-4">Create Link</h3>

            <Form onSubmit={handleSubmit} className="mx-2">
                <Form.Group className="mb-3">
                    <Form.Label>Ticket Type</Form.Label>
                    <Form.Select
                        name="ticket_type"
                        value={formData.ticket_type}
                        onChange={handleChange}
                    >
                        {ticketTypes.map((tt, i) => {
                            return (
                                <option key={i} value={tt.value}>
                                    {tt.name}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Ticket ID / Nr.</Form.Label>
                    <Form.Control
                        type="text"
                        name="ticket_id"
                        value={formData.ticket_id}
                        required
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Button
                        className="btn btn-primary rounded-pill"
                        type="submit"
                        disabled={submitDisabled}
                    >
                        Create
                    </Button>
                </Form.Group>
            </Form>

            <LinkModal
                uploadURL={uploadURL}
                show={showModal}
                headerMessage="Link created!"
                message={<></>}
                close={closeUploadDone}
            />
        </div>
    );
};

export default GenerateComponent;

import React, { useCallback, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Turnstile from "../Turnstile/Turnstile";

import Config from "../config.json";
import { apiURL } from "../urls";
import { useAPIError } from "../hooks/useAPIError";
import useAuth from "../hooks/useAuth";
import { privateClient } from "../api/api";

import "../App.css";

interface OAuth2Provider {
    name: string;
    display: string;
}

interface SigninRequest {
    email: string;
    password: string;
    reCaptchaResponse: string;
}

const oauth2Providers = Config.OAUTH2_PROVIDERS as Array<OAuth2Provider>;

const LoginComponent: React.FC = () => {
    const { setAuth } = useAuth();
    const [signinDisabled, setSigninDisabled] = useState<boolean>(true);
    const initialFormData: SigninRequest = {
        email: "",
        password: "",
        reCaptchaResponse: "",
    };
    const [formData, setFormData] = useState(initialFormData);
    const { addError, removeError } = useAPIError();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as { from: Location };
    const from = state?.from?.pathname || "/";

    const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => {
            let data = { ...prev, [event.target.name]: event.target.value };
            if (data.email && data.password) {
                setSigninDisabled(false);
            } else {
                setSigninDisabled(true);
            }
            return data;
        });
    };

    const onSignIn = async (e: React.FormEvent) => {
        e.preventDefault();
        removeError();

        window.turnstile.execute();
    };

    const onVerify = async (token: string) => {
        formData.reCaptchaResponse = token;
        setSigninDisabled(true);

        try {
            await privateClient.request({
                method: "POST",
                url: "/api/auth/signin",
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            setAuth!({ loggedIn: true });
            navigate(from, { replace: true });
        } catch (err) {
            setSigninDisabled(false);
            window.turnstile.reset();
            addError(new Error("Authentication failed"));
        }
    };

    const onSignInProvider = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const button: HTMLButtonElement = e.currentTarget;
        let providerCfg = oauth2Providers.find((el) => el.name === button.name);
        let state = formatState(from, button.name);
        let authUrl = getAuthorizeUrl(providerCfg!, state);
        openPage(authUrl);
    };

    const getAuthorizeUrl = (cfg: OAuth2Provider, state: string) => {
        let params = new URLSearchParams({
            state: state,
        });

        return `${apiURL}/api/auth/oauth2/${cfg.name}?${params.toString()}`;
    };

    const onRecaptchaError = useCallback(() => {
        addError(new Error("Error connecting to the reCAPTCHA service"));
    }, [addError]);

    return (
        <div className="main-container">
            <h3 className="d-flex justify-content-center m-4">Sign In</h3>

            {Config.ENABLE_DP_LOGIN && (
                <Form onSubmit={onSignIn}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="example@email.com"
                            autoComplete="off"
                            required
                            onChange={handleFormChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder="password"
                            required
                            onChange={handleFormChange}
                        />
                    </Form.Group>

                    <Form.Group className="">
                        <Button
                            className="btn btn-dark justify-content-center"
                            type="submit"
                            disabled={signinDisabled}
                        >
                            Sign In
                        </Button>
                    </Form.Group>
                </Form>
            )}

            <div className="d-flex justify-content-center align-content-center align-items-center my-5">
                <span>Continue with&nbsp;</span>
                {oauth2Providers.map((provider: OAuth2Provider, i: number) => {
                    return (
                        <Button
                            className="btn btn-light"
                            name={provider.name}
                            onClick={onSignInProvider}
                            key={provider.name}
                        >
                            {provider.display}{" "}
                        </Button>
                    );
                })}
            </div>

            <Turnstile
                sitekey={Config.RECAPTCHA_SITE_KEY}
                onVerify={onVerify}
                onError={onRecaptchaError}
                onTimeout={() => {
                    window.turnstile.reset();
                }}
                onRenderError={onRecaptchaError}
                theme="dark"
                size="normal"
                appearance="interaction-only"
                className="mt-4"
                execution="execute"
            />
        </div>
    );
};

const formatState = (url: string, providerName: string): string => {
    return btoa(url + ";" + providerName);
};

const openPage = (url: string) => {
    window.location.href = url;
};

export default LoginComponent;

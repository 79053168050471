import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, ListGroup, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { privateClient, getUploadStatus, checkAuth } from "../api/api";
import { FileInfo, Preview, UploadStatus } from "../Types/Types";
import { useAPIError } from "../hooks/useAPIError";

import { convertUnits } from "../utils/Units";
import { apiURL } from "../urls";

import "../FileList/FileList.css";

const DownloadComponent: React.FC = () => {
    const params = useParams();
    const downloadRef = useRef<HTMLAnchorElement | null>(null);
    const [status, setStatus] = useState<UploadStatus>({
        status: "loading...",
        total_files: 0,
        uploaded_files: 0,
        last_updated: "",
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const { addError } = useAPIError();
    const [files, setFiles] = useState<FileInfo[]>([]);

    const previewURL = `${apiURL}/api/upload/${params.downloadId}/preview`;
    const downloadURL = `${apiURL}/api/download/${params.downloadId}`;

    useEffect(() => {
        if (!isReady) {
            return;
        }
        privateClient
            .request<Preview>({
                method: "GET",
                url: previewURL,
            })
            .then((resp) => {
                setIsLoading(false);
                setFiles(resp.data.files);
            })
            .catch((err) => {
                setIsLoading(false);
                addError(err);
            });
    }, [isReady, previewURL, addError]);

    const checkStatus = useCallback(() => {
        getUploadStatus(params.downloadId!)
            .then((resp) => {
                if (resp?.data?.status) {
                    setStatus(resp.data);
                }
                if (resp.data.status === "done") {
                    setIsReady(true);
                } else if (resp.data.status !== "failed") {
                    setTimeout(checkStatus, 10000);
                }
            })
            .catch((err) => {
                setIsReady(true);
                addError(err);
            });
    }, [params, addError]);

    useEffect(() => {
        checkStatus();
    }, [checkStatus]);

    /* Called when download fails (auth expires) */
    const onIframeLoad = (e: any) => {
        checkAuth().catch(addError);
    };

    const renderContainer = () => {
        if (isLoading) {
            return (
                <div className="mx-2 h-100 d-flex flex-column justify-content-center align-items-center">
                    <p className="fs-6">Status: {status.status}</p>
                    <p className="fs-6">
                        Files uploaded: {status.uploaded_files} /{" "}
                        {status.total_files}
                    </p>
                    <p className="fs-6">Last updated: {status.last_updated}</p>
                    {!status.status.includes("failed") ? (
                        <Spinner animation="border" as="span" />
                    ) : (
                        <></>
                    )}
                </div>
            );
        }

        if (isReady && files.length === 0) {
            return (
                <>
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <h4>No files found... </h4>
                    </div>
                </>
            );
        }

        return (
            <>
                <div className="file-list-scrollable">
                    <div className="d-flex my-2">
                        <ListGroup className="file-list">
                            {files.map((f, i) => (
                                <ListGroup.Item className="mt-3 border" key={i}>
                                    <div className="d-flex">
                                        <Col md="auto">
                                            <i className="bi bi-file-earmark-text-fill icon-size"></i>
                                        </Col>
                                        <Col md="auto" className="ms-2">
                                            <span className="file-name">
                                                {f.name}
                                            </span>
                                            <span className="file-size">
                                                {convertUnits(f.size)}
                                            </span>
                                        </Col>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                </div>

                {files.length > 0 && (
                    <div className="my-2 d-flex flex-column justify-content-end align-items-center">
                        <a
                            href={downloadURL}
                            ref={downloadRef}
                            target="idownload"
                            className="btn btn-primary rounded-pill"
                            download
                        >
                            Download
                        </a>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <Container
                fluid
                className="d-flex justify-content-center align-items-center h-100"
            >
                <iframe
                    title="idownload"
                    name="idownload"
                    width={0}
                    height={0}
                    onLoad={onIframeLoad}
                ></iframe>

                <Container className="col-md-3 p-4">
                    <div className="main-container">{renderContainer()}</div>
                </Container>
            </Container>
        </>
    );
};

export default DownloadComponent;

import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { checkAuth } from "./api/api";

const RequireAuth: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { auth, setAuth } = useAuth();
    const location = useLocation();

    useEffect(() => {
        checkAuth()
            .then(() => {
                setAuth!({ loggedIn: true });
                setIsLoading(false);
            })
            .catch(() => {
                setAuth!({ loggedIn: false });
                setIsLoading(false);
            });
    }, [setAuth]);

    return isLoading ? (
        <></>
    ) : auth?.loggedIn ? (
        <Outlet />
    ) : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};

export default RequireAuth;

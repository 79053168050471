import { client, retryClient } from "../api/api";
import { UploadRequest, UploadRequestFile, UploadResponse } from "../Types/Types";
import Config from "../config.json";
import { appURL } from "../urls";
import { Method } from "axios";

const chunkSize: number = Config.CHUNK_SIZE;

export class UploadSession {
    readonly files: File[];
    readonly setProgress: React.Dispatch<
        React.SetStateAction<Map<string, number>>
    >;
    uploadId: string = "";

    constructor(
        files: File[],
        setProgress: React.Dispatch<React.SetStateAction<Map<string, number>>>,
        uploadId: string = ""
    ) {
        this.files = files;
        this.setProgress = setProgress;
        this.uploadId = uploadId;
    }

    downloadURL(): string {
        return `${appURL}/d/${this.uploadId}`;
    }

    async upload(reCaptchaResponse: string) {
        let req: UploadRequest = {
            files: this.files.map((f: File): UploadRequestFile => {
                return {
                    name: f.name,
                    size: f.size,
                };
            }),
            reCaptchaResponse: reCaptchaResponse,
        };
        let url = "/api/upload/";
        let method: Method = "POST";
        if (this.uploadId) {
            url = `/api/upload/${this.uploadId}`;
            method = "PATCH";
        }

        let resp: UploadResponse = await client
            .request({
                method: method,
                url: url,
                data: req,
            })
            .then((r) => r.data);

        this.uploadId = resp.id;

        for (let apiFile of resp.files) {
            let file: File = this.files.find((el) => el.name === apiFile.name)!;

            const updateProgress = (progress: number) => {
                return (prev: Map<string, number>) => {
                    return new Map([
                        ...Array.from(prev.entries()),
                        [apiFile.name, progress],
                    ]);
                };
            };

            const putChunk = async (chunkNr: number, offset: number) => {
                let chunk: Blob = file.slice(offset, offset + chunkSize);

                await retryClient({
                    method: "PUT",
                    url: `/api/upload/${resp.id}/${apiFile.id}/${chunkNr}`,
                    data: chunk,
                    headers: {
                        "Content-Type": "application/octet-stream",
                    },
                    onUploadProgress: (progressEvent) => {
                        let progress =
                            ((offset + progressEvent.loaded) / file.size) * 100;
                        progress = Math.min(progress, 99);
                        this.setProgress(updateProgress(progress));
                    },
                });
            };

            if (file.size === 0) {
                await putChunk(1, 0);
            }

            for (
                let offset = 0, chunkNr = 1;
                offset < file.size;
                offset += chunkSize, chunkNr++
            ) {
                await putChunk(chunkNr, offset);
            }

            this.setProgress(updateProgress(100))
        }
    }
}

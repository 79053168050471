import React from "react";

const UnauthorizedPage: React.FC = () => {
    return (
        <div className="main-container">
            <strong className="d-flex justify-content-center m-4">
                Unauthorized
            </strong>
        </div>
    );
};

export default UnauthorizedPage;

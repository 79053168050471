import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import axios, { AxiosError } from "axios";
import { useAPIError } from "./hooks/useAPIError";
import { ServerError } from "./Types/Types";
import useAuth from "./hooks/useAuth";

export const APIErrorNotification: React.FC = () => {
    const { error, removeError } = useAPIError();
    const { setAuth } = useAuth();
    const traceId = (error as AxiosError)?.response?.headers["trace-id"];
    const serverError = error as AxiosError<ServerError>;
    let simpleErr = false;
    error && console.log("error", error);

    let message = "";

    useEffect(() => {
        if (serverError?.response?.status === 401) {
            setAuth!({ loggedIn: false });
        }
    }, [serverError, setAuth]);

    if (axios.isAxiosError(error)) {
        if (serverError && serverError?.response) {
            message = serverError.response?.data?.message || "An error occured";
        }
        let status = serverError?.response?.status;
        if (status === 404 || status === 401) {
            simpleErr = true;
        }
    }
    if (!message) {
        if (error?.message) {
            message = error!.message;
        } else {
            message = "An error occured";
        }
    }

    const handleClose = () => {
        removeError();
    };

    return (
        <>
            {error && (
                <div className="fixed-bottom">
                    <Alert
                        variant="danger"
                        onClose={handleClose}
                        dismissible
                        show={!!error}
                        className="d-flex align-items-center my-0"
                    >
                        <div>
                            <h5>
                                <i className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"></i>
                                {message && <>{message}.</>}{" "}
                                {!simpleErr && traceId && (
                                    <>(Trace ID: {traceId})</>
                                )}{" "}
                            </h5>
                        </div>
                    </Alert>
                </div>
            )}
        </>
    );
};

import React from "react";

const Logo: React.FC = () => {
    return (
        <>
            <img src="/bd-background.jpg" className="bg" alt=""></img>
            <a href="https://www.bitdefender.com/">
                <img
                    className="logo"
                    src="/Bitdefender-B-Icon-Black.png"
                    width={44}
                    height={44}
                    alt="B"
                ></img>
            </a>

            <a href="https://www.bitdefender.com/">
                <svg
                    className="logo-footer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="210"
                    height="30"
                >
                    <title>Bitdefender</title>
                    <use
                        href="/Bitdefender-Logo-BW-web.svg#logo"
                        fill="white"
                    ></use>
                </svg>
            </a>
        </>
    );
};

export default Logo;

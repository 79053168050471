export const convertUnits = (nBytes: number): string => {
    const GB = 1024 * 1024 * 1024;
    const MB = 1024 * 1024;
    const KB = 1024;
    const toUnit = (nBytes: number, unit: number): string => {
        return (nBytes / unit).toFixed(2);
    };
    if (nBytes > GB) {
        return `${toUnit(nBytes, GB)} GB`;
    }
    if (nBytes > MB) {
        return `${toUnit(nBytes, MB)} MB`;
    }
    if (nBytes > KB) {
        return `${toUnit(nBytes, KB)} KB`;
    }

    return `${nBytes} B`;
};

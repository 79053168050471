import React, { createContext, useState } from "react";

interface IAuth {
    loggedIn: boolean;
}

interface IAuthContext {
    auth: IAuth | null;
    setAuth: React.Dispatch<React.SetStateAction<IAuth | null>> | null;
}

export const AuthContext = createContext<IAuthContext>({
    auth: null,
    setAuth: null,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    let [auth, setAuth] = useState<IAuth | null>({ loggedIn: false });

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

import React from "react";
import { Container } from "react-bootstrap";

import UploadMediaComponent from "./UploadComponent";

import "./Upload.css";

interface IProps {}

interface IState {
    hasError?: boolean;
}

class Upload extends React.Component<IProps, IState> {
    render() {
        if (this.state?.hasError) {
            return (
                <Container
                    fluid
                    className="d-flex justify-content-center align-items-center h-100"
                >
                    <h3>Error rendering page!</h3>
                </Container>
            );
        }
        return (
            <UploadMediaComponent/>
        );
    }

    componentDidCatch(error: any, info: any) {
        console.log(error);
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true, exception: error };
    }
}

export default Upload;
